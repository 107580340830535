import "@lottiefiles/lottie-player";
import "remodal";
import { Fancybox } from "@fancyapps/ui";
import SplitType from 'split-type';

jQuery(document).ready(function($) {

/* ====================== INIT ======================  */
  const text = new SplitType('[data-splitting]');

    // Get the word elements
  const wordElements = document.querySelectorAll('.heading .word');

  // Iterate over each word element
  wordElements.forEach(wordElement => {
    // Create a new span element
    const span = document.createElement('i');
    
    // Move the content of the word element into the span element
    while (wordElement.firstChild) {
      span.appendChild(wordElement.firstChild);
    }

    // Append the span element back into the word element
    wordElement.appendChild(span);
  });

/* ====================== FANCYBOX SETTINGS ======================  */
   Fancybox.bind("[data-fancybox]");

 /* ====================== REMOVE EMPTY P TAG ======================  */
    $('p:not(.no-remove)').each(function() {
        var $this = $(this);
        if ($this.html().replace(/\s|&nbsp;/g, '').length == 0)
            $this.remove();
    });

/* ====================== INIT FUNCSTIONS ======================  */

    $(".timetable__table__days__tab").on('click', function(e) {
        e.preventDefault();
        var day = $(this).data('day');
        $(".timetable__table__days__tab").not($('.timetable__table__days__tab[data-day="'+day+'"]')).removeClass('active');
        $('.timetable__table__days__tab[data-day="'+day+'"]').addClass('active');
        $(".timetable__table__body").not($('.timetable__table__body[data-day="'+day+'"]')).removeClass('active');
        $('.timetable__table__body[data-day="'+day+'"]').addClass('active');
    });


    $(".timetable__table__days__next, .timetable__table__days__prev").on('click', function(e) {
        e.preventDefault();
        var day = parseInt($('.timetable__table__days__tab.active').data('day'));

        if($(this).hasClass('timetable__table__days__next')) {
            if(day == 6) {
                var day = 0;
            } else {
                var day = day+1;
            }
        } else {
            if(day == 0) {
                var day = 6;
            } else {
                var day = day-1;
            }
        }

        $(".timetable__table__days__tab").not($('.timetable__table__days__tab[data-day="'+day+'"]')).removeClass('active');
        $('.timetable__table__days__tab[data-day="'+day+'"]').addClass('active');
        $(".timetable__table__body").not($('.timetable__table__body[data-day="'+day+'"]')).removeClass('active');
        $('.timetable__table__body[data-day="'+day+'"]').addClass('active');
    });

    $(".timetable__tabs__tab").on('click', function(e) {
        e.preventDefault();
        var type = $(this).data('type');
        console.log(type);
        $(".timetable__tabs__tab").not(this).removeClass('active');
        $(this).addClass('active');
        $(".timetable__table").not($('.timetable__table__body[data-type="'+type+'"]')).removeClass('active');
        $('.timetable__table[data-type="'+type+'"]').addClass('active');

        if(type == 'swim') {
            $('.timetable__table__footer__pool').show();
        } else {
            $('.timetable__table__footer__pool').hide();
        }
    });


    $('[data-remodal-target]').on('click', function() {
        var title = $(this).parent().parent().find('.title').text();
        var duration = $(this).parent().parent().find('.duration').text();
        var instructor = $(this).parent().parent().find('.instructor').text();
        var location = $(this).parent().parent().find('.location').text();
        var type = $(this).data('type');
        var content = $(this).parent().next('.content').html();

        $('.timetable-modal .heading--xs').text(title);
        $('.timetable-modal .duration i').text(duration);
        $('.timetable-modal .instructor i').text(instructor);
        $('.timetable-modal .location i').text(location);

        if(type == null || type == '') {
            $('.timetable-modal .type').hide();
        } else {
            $('.timetable-modal .type').show();
            $('.timetable-modal .type i').text(type);
        }

        if(content == null || content == '') {
            $('.timetable-modal .body-content').hide();
        } else {
            $('.timetable-modal .body-content').show();
            $('.timetable-modal .body-content p').html(content);
        }
        
    });


/* ====================== MOBILE NAV ======================  */
  function mobile_nav() {
    if($(window).width() <= 1025) {
      $('.header__nav-toggle').unbind().on("click", function() {
        $('.header').toggleClass('header--nav-open');
        $('html,body').toggleClass('noscroll');
      });

        $(window).scroll(function(){
            var windowScroll = $(window).scrollTop();

            if(windowScroll > 70) {
              $(".header").addClass('header--scroll');
            } else {
              $(".header").removeClass('header--scroll');
            }
        });
    } else {
        $(window).scroll(function(){
            var windowScroll = $(window).scrollTop();

            if(windowScroll > 50) {
              $(".header").addClass('header--scroll');
            } else {
              $(".header").removeClass('header--scroll');
            }
        });
    }
  }

  mobile_nav();

  $( window ).resize(function() {
    mobile_nav();
  });

    $(window).scroll(function(){
        var windowScroll = $(window).scrollTop();

        if(windowScroll > 200 && $('.fixed-join').length) {
          $('.fixed-join').addClass('show');
        } else {
          $('.fixed-join').removeClass('show');
        }
    });

/* ====================== FAQ ACCORDIONS ======================  */
    $(".faq__q").click(function(){
        // Toggle the visibility of the answer associated with this question
        $(this).next(".faq__a").slideToggle();
        
        // Toggle the active class for styling purposes
        $(this).toggleClass("active");
    });

});
