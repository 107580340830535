import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


document.querySelectorAll('.heading[data-splitting]').forEach(function(heading) {
	gsap.delayedCall(.5, animateWords);
	var duration = 1.4;
	var stagger = 0.2;

	function animateWords() {
		gsap.to(heading.querySelectorAll(".word"), {
		  scrollTrigger: {
		    trigger: heading,
		    start: 'top 80%',
		  },
		  webkitClipPath: "polygon(0% 0%, 102% 0%, 102% 102%, 0% 102%)",
		  clipPath: "polygon(0% 0%, 102% 0%, 102% 102%, 0% 102%)",
		  ease: "power4.out",
		  opacity: 1,
		  duration: duration,
		  stagger: stagger
		});

		gsap.to(heading.querySelectorAll(".word i"), {
		  scrollTrigger: {
		    trigger: heading,
		    start: 'top 80%',
		  },
		  y: 0,
		  ease: "power4.out",
		  duration: duration,
		  stagger: stagger
		});
	};
});
