import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

    const facilitiesSlider = new Swiper('.facilities-slider__slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        allowTouchMove: true,
        speed: 700,
        autoplay: {
            delay: 4000,
        },
        navigation: {
          nextEl: ".facilities-slider__slider__next",
          prevEl: ".facilities-slider__slider__prev",
        },

        breakpoints: {
            1200: {
                slidesPerView: 3,
            },
            650: {
                slidesPerView: 2,
            },
        }
    });

    const testimonialsSlider = new Swiper('.testimonials-slider__slider .swiper', {
        slidesPerView: 1,
        spaceBetween: 30,
        allowTouchMove: true,
        speed: 700,
        effect: "coverflow",
        navigation: {
          nextEl: ".testimonials-slider__slider__next",
          prevEl: ".testimonials-slider__slider__prev",
        },
    });

    const gallerySlider = new Swiper('.gallery-slider__slider .swiper', {
        speed: 1000,
        loop: true,
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 15,
        allowTouchMove: true,
        navigation: {
            nextEl: ".gallery-slider__slider__next",
            prevEl: ".gallery-slider__slider__prev",
        },
        pagination: {
          el: '.gallery-slider__pagination',
          dynamicBullets: true,
          clickable: true
        },
        breakpoints: {
            650: {
                slidesPerView: 'auto',
            },
        }
    });
